{
  "name": "lainox-nabook",
  "version": "1.5.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --proxy-config proxy.conf.local.json --configuration local",
    "start:develop": "ng serve --proxy-config proxy.conf.develop.json --configuration develop",
    "start:stage": "ng serve --proxy-config proxy.conf.stage.json --configuration stage",
    "start:prod": "ng serve --proxy-config proxy.conf.json --configuration production",
    "build": "ng build",
    "build:stage": "ng build --configuration stage",
    "build:develop": "ng build --configuration develop",
    "watch": "ng build --watch --configuration stage",
    "test": "ng test",
    "upgrade-angular": "ng update @angular/core@18 @angular/cli@18",
    "update-build-system": "ng update @angular/cli --name use-application-builder"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^18.0.0",
    "@angular/animations": "^18.1.2",
    "@angular/common": "^18.1.2",
    "@angular/compiler": "^18.1.2",
    "@angular/core": "^18.1.2",
    "@angular/forms": "^18.1.2",
    "@angular/platform-browser": "^18.1.2",
    "@angular/platform-browser-dynamic": "^18.1.2",
    "@angular/router": "^18.1.2",
    "@biesbjerg/ngx-translate-extract": "^7.0.4",
    "@biesbjerg/ngx-translate-extract-marker": "^1.0.0",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@ng-select/ng-select": "^13.5.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@popperjs/core": "^2.11.8",
    "@swimlane/ngx-datatable": "^20.1.0",
    "angular-plotly.js": "^5.2.2",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.3",
    "chartjs-adapter-moment": "^1.0.1",
    "filtrable-data-source": "^2.1.0",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^18.0.0",
    "mobile-drag-drop": "^3.0.0-rc.0",
    "moment": "^2.30.1",
    "ng2-charts": "^6.0.1",
    "ngx-bootstrap": "^18.0.2",
    "ngx-drag-drop": "^18.0.2",
    "ngx-progressbar": "^11.1.0",
    "ngx-toastr": "^19.0.0",
    "plotly.js-dist-min": "^2.34.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.2",
    "@angular/cli": "^18.1.2",
    "@angular/compiler-cli": "^18.1.2",
    "@angular/localize": "^18.1.2",
    "@openapitools/openapi-generator-cli": "^2.13.4",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^22.0.0",
    "@types/plotly.js": "^2.33.3",
    "@types/plotly.js-dist-min": "^2.3.4",
    "husky": "^9.1.4",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "lint-staged": "^15.2.7",
    "prettier": "^3.3.3",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "~5.5.4"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "*.{ts}": [
      "prettier --write",
      "tslint --fix"
    ],
    "*.{js,json,html,css,scss,md}": "prettier --write"
  }
}
