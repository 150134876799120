/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface KeycloakBaseUserCreationDtoNBK {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  country?: string;
  locale?: string;
  companyName?: string;
  roles?: Set<KeycloakBaseUserCreationDtoNBK.RolesEnum>;
}
export namespace KeycloakBaseUserCreationDtoNBK {
  export type RolesEnum =
    | 'DEFAULT_ROLE'
    | 'CHEF_EXECUTIVE'
    | 'CHEF_LAINOX'
    | 'USER_MANAGER'
    | 'SUPERUSER'
    | 'LAINOX_SERVICE'
    | 'LAINOX_SERVICE_ADMIN'
    | 'SERVICE_ADMIN'
    | 'SERVICE';
  export const RolesEnum = {
    DefaultRole: 'DEFAULT_ROLE' as RolesEnum,
    ChefExecutive: 'CHEF_EXECUTIVE' as RolesEnum,
    ChefLainox: 'CHEF_LAINOX' as RolesEnum,
    UserManager: 'USER_MANAGER' as RolesEnum,
    Superuser: 'SUPERUSER' as RolesEnum,
    LainoxService: 'LAINOX_SERVICE' as RolesEnum,
    LainoxServiceAdmin: 'LAINOX_SERVICE_ADMIN' as RolesEnum,
    ServiceAdmin: 'SERVICE_ADMIN' as RolesEnum,
    Service: 'SERVICE' as RolesEnum
  };
}
