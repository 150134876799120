<ngx-slider
  [formControl]="control"
  [class]="color"
  [options]="options"
  (valueChange)="onSliderChange($event)">
></ngx-slider>
<div
  class="d-flex align-items-center"
  [ngClass]="{
    'justify-content-between': showButton && !disabled,
    'justify-content-center': !(showButton && !disabled)
  }"
>
  <button
    class="btn-command"
    (click)="decrease()"
    [disabled]="control.value! <= (floor || 0)"
    *ngIf="showButton && !disabled"
  >
    <i class="fa fa-minus"></i>
  </button>
  <div *ngIf="showValue" class="text-center">
    <ng-container [ngSwitch]="true">
      <span *ngSwitchCase="time">
        {{ control.value | timeRange }}
      </span>
      <span *ngSwitchCase="temperature">
        <span *ngIf="!disabled">{{
          tempUnit ? (control.value | temperatureUnit: tempUnit) : control.value
        }}</span>
        <span *ngIf="disabled">--</span>
      </span>
      <span *ngSwitchDefault>
        <span *ngIf="!disabled">{{ control.value }}</span>
        <span *ngIf="disabled">--</span>
      </span>
    </ng-container>
    <span *ngIf="percentage && !disabled">%</span>
  </div>
  <button
    class="btn-command"
    (click)="increase()"
    [disabled]="ceil && control.value >= ceil"
    *ngIf="showButton && !disabled"
  >
    <i class="fa fa-plus"></i>
  </button>
</div>
