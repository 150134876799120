/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceConfigurationDtoNBK } from './deviceConfigurationDto';

export interface DeviceDtoNBK {
  id?: number;
  name?: string;
  hardwareId?: string;
  firmware?: string;
  serial?: string;
  online?: boolean;
  ownerLoggedIn?: boolean;
  model?: DeviceDtoNBK.ModelEnum;
  type?: DeviceDtoNBK.TypeEnum;
  owner?: string;
  ownerUsername?: string;
  registrationDate?: string;
  productionDate?: string;
  lastSync?: string;
  lastInfoUpdate?: string;
  currentOwnerFirstAssociation?: string;
  deviceConfiguration?: DeviceConfigurationDtoNBK;
}
export namespace DeviceDtoNBK {
  export type ModelEnum = 'ORACLE' | 'NABOO' | 'NEO';
  export const ModelEnum = {
    Oracle: 'ORACLE' as ModelEnum,
    Naboo: 'NABOO' as ModelEnum,
    Neo: 'NEO' as ModelEnum
  };
  export type TypeEnum =
    | 'ORACS'
    | 'ORACB'
    | 'ORACXL'
    | 'NEO'
    | 'NABOO'
    | 'PUFF'
    | 'COMPACT'
    | 'AROMA'
    | 'FOODY';
  export const TypeEnum = {
    Oracs: 'ORACS' as TypeEnum,
    Oracb: 'ORACB' as TypeEnum,
    Oracxl: 'ORACXL' as TypeEnum,
    Neo: 'NEO' as TypeEnum,
    Naboo: 'NABOO' as TypeEnum,
    Puff: 'PUFF' as TypeEnum,
    Compact: 'COMPACT' as TypeEnum,
    Aroma: 'AROMA' as TypeEnum,
    Foody: 'FOODY' as TypeEnum
  };
}
