/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CookingSettingsDtoNBK } from './cookingSettingsDto';
import { AirventDtoNBK } from './airventDto';
import { PhaseActionBoDtoNBK } from './phaseActionBoDto';
import { FanDtoNBK } from './fanDto';

export interface PhaseBoDtoNBK {
  id?: number;
  phaseType?: string;
  cookingType?: PhaseBoDtoNBK.CookingTypeEnum;
  magnetron?: number;
  fanSpeed?: number;
  temperature?: number;
  time?: number;
  cookingSettings?: CookingSettingsDtoNBK;
  fan?: FanDtoNBK;
  softDry?: boolean;
  vaporType?: PhaseBoDtoNBK.VaporTypeEnum;
  moisturizing?: number;
  autoclimate?: number;
  aroma?: PhaseBoDtoNBK.AromaEnum;
  smokegrill?: PhaseBoDtoNBK.SmokegrillEnum;
  airvent?: AirventDtoNBK;
  actions?: Array<PhaseActionBoDtoNBK>;
}
export namespace PhaseBoDtoNBK {
  export type CookingTypeEnum =
    | 'CONVECTION'
    | 'MICROWAVE'
    | 'COMBO'
    | 'STEAM'
    | 'SHOCK_FREEZING'
    | 'BLAST_CHILLING'
    | 'SLOW_COOKING'
    | 'LEAVENING';
  export const CookingTypeEnum = {
    Convection: 'CONVECTION' as CookingTypeEnum,
    Microwave: 'MICROWAVE' as CookingTypeEnum,
    Combo: 'COMBO' as CookingTypeEnum,
    Steam: 'STEAM' as CookingTypeEnum,
    ShockFreezing: 'SHOCK_FREEZING' as CookingTypeEnum,
    BlastChilling: 'BLAST_CHILLING' as CookingTypeEnum,
    SlowCooking: 'SLOW_COOKING' as CookingTypeEnum,
    Leavening: 'LEAVENING' as CookingTypeEnum
  };
  export type VaporTypeEnum = 'ECO' | 'TURBO';
  export const VaporTypeEnum = {
    Eco: 'ECO' as VaporTypeEnum,
    Turbo: 'TURBO' as VaporTypeEnum
  };
  export type AromaEnum = 'OFF' | 'SOFT' | 'MEDIUM' | 'HARD';
  export const AromaEnum = {
    Off: 'OFF' as AromaEnum,
    Soft: 'SOFT' as AromaEnum,
    Medium: 'MEDIUM' as AromaEnum,
    Hard: 'HARD' as AromaEnum
  };
  export type SmokegrillEnum = 'OFF' | 'SOFT' | 'MEDIUM' | 'HARD';
  export const SmokegrillEnum = {
    Off: 'OFF' as SmokegrillEnum,
    Soft: 'SOFT' as SmokegrillEnum,
    Medium: 'MEDIUM' as SmokegrillEnum,
    Hard: 'HARD' as SmokegrillEnum
  };
}
