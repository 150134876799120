/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceDisplayDtoNBK {
  deviceId?: number;
  deviceName?: string;
  deviceSerial?: string;
  deviceModel?: DeviceDisplayDtoNBK.DeviceModelEnum;
  displayId?: number;
  displayName?: string;
}
export namespace DeviceDisplayDtoNBK {
  export type DeviceModelEnum = 'ORACLE' | 'NABOO' | 'NEO';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum,
    Naboo: 'NABOO' as DeviceModelEnum,
    Neo: 'NEO' as DeviceModelEnum
  };
}
