/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { IngredientBoDtoNBK } from '../model/models';
import { ResultSetIngredientBoDtoNBK } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {
  protected basePath = 'http://localhost/cloud/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Remove specific entity
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteIngredient(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public deleteIngredient(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public deleteIngredient(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public deleteIngredient(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteIngredient.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/bo/ingredient/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get all ingredients. By default return users o ics ingredients.
   * @param acceptLanguage
   * @param $top Max number item to get
   * @param $skip How many record skip before the first item
   * @param $count True to get also the total number of item the server has
   * @param $orderby Order by the result
   * @param $filter Filter the result
   * @param $search String to search in items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllIngredients(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetIngredientBoDtoNBK>;
  public getAllIngredients(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetIngredientBoDtoNBK>>;
  public getAllIngredients(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetIngredientBoDtoNBK>>;
  public getAllIngredients(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if ($top !== undefined && $top !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$top,
        '$top'
      );
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$skip,
        '$skip'
      );
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$count,
        '$count'
      );
    }
    if ($orderby !== undefined && $orderby !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$orderby,
        '$orderby'
      );
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$filter,
        '$filter'
      );
    }
    if ($search !== undefined && $search !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$search,
        '$search'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<ResultSetIngredientBoDtoNBK>(
      `${this.configuration.basePath}/bo/ingredient`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get single Ingredient by Id
   * @param id
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIngredientById(
    id: number,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<IngredientBoDtoNBK>;
  public getIngredientById(
    id: number,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<IngredientBoDtoNBK>>;
  public getIngredientById(
    id: number,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<IngredientBoDtoNBK>>;
  public getIngredientById(
    id: number,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getIngredientById.'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<IngredientBoDtoNBK>(
      `${this.configuration.basePath}/bo/ingredient/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new entity
   * @param acceptLanguage
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<IngredientBoDtoNBK>;
  public insertIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<IngredientBoDtoNBK>>;
  public insertIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<IngredientBoDtoNBK>>;
  public insertIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.post<IngredientBoDtoNBK>(
      `${this.configuration.basePath}/bo/ingredient`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Mark an ingredient as ics
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public markIcsIngredient(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain; charset&#x3D;utf-8' }
  ): Observable<string>;
  public markIcsIngredient(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<string>>;
  public markIcsIngredient(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<string>>;
  public markIcsIngredient(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling markIcsIngredient.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (id !== undefined) {
      formParams = (formParams.append('id', <any>id) as any) || formParams;
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<string>(
      `${this.configuration.basePath}/bo/ingredient/markIcs`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update an entity
   * @param acceptLanguage
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<IngredientBoDtoNBK>;
  public updateIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<IngredientBoDtoNBK>>;
  public updateIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<IngredientBoDtoNBK>>;
  public updateIngredient(
    acceptLanguage?: string,
    body?: IngredientBoDtoNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<IngredientBoDtoNBK>(
      `${this.configuration.basePath}/bo/ingredient`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
