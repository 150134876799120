/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConsumptionDataPointNBK } from './consumptionDataPoint';

export interface ConsumptionDataNBK {
  type?: ConsumptionDataNBK.TypeEnum;
  total?: number;
  data?: Array<ConsumptionDataPointNBK>;
}
export namespace ConsumptionDataNBK {
  export type TypeEnum = 'ENERGY' | 'WATER' | 'CALFREE' | 'COMBICLEAN';
  export const TypeEnum = {
    Energy: 'ENERGY' as TypeEnum,
    Water: 'WATER' as TypeEnum,
    Calfree: 'CALFREE' as TypeEnum,
    Combiclean: 'COMBICLEAN' as TypeEnum
  };
}
