/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceDisplayRequestParamNBK {
  deviceName?: string;
  deviceSerial?: string;
  displayName?: string;
  deviceModel: DeviceDisplayRequestParamNBK.DeviceModelEnum;
  cookingMode: DeviceDisplayRequestParamNBK.CookingModeEnum;
  displayMode: DeviceDisplayRequestParamNBK.DisplayModeEnum;
  top: number;
  skip: number;
}
export namespace DeviceDisplayRequestParamNBK {
  export type DeviceModelEnum = 'ORACLE' | 'NABOO' | 'NEO';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum,
    Naboo: 'NABOO' as DeviceModelEnum,
    Neo: 'NEO' as DeviceModelEnum
  };
  export type CookingModeEnum = 'COMBI' | 'HSO' | 'NABOO' | 'NEO';
  export const CookingModeEnum = {
    Combi: 'COMBI' as CookingModeEnum,
    Hso: 'HSO' as CookingModeEnum,
    Naboo: 'NABOO' as CookingModeEnum,
    Neo: 'NEO' as CookingModeEnum
  };
  export type DisplayModeEnum = 'SINGLE_DISPLAY' | 'MULTI_DISPLAY';
  export const DisplayModeEnum = {
    SingleDisplay: 'SINGLE_DISPLAY' as DisplayModeEnum,
    MultiDisplay: 'MULTI_DISPLAY' as DisplayModeEnum
  };
}
