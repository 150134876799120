import { HoldingDtoNBK, PhaseBoDtoNBK, PreheatDtoNBK, RecipeBoDtoNBK } from '../api/nbk';
import { CookingModeEnum, DeviceModelEnum } from './utils';

export type PhaseBoDtoNBKComplete = PhaseBoDtoNBK & { group: number };

export type PhaseControlTypeEnum =
  | 'TEMPERATURE'
  | 'DELTA_TEMPERATURE'
  | 'TIME'
  | 'SPILLONE'
  | 'AUTOCLIMATE'
  | 'VAPOR_TYPE'
  | 'WAVE_POWER'
  | 'FAN';
export const PhaseControlTypeEnum = {
  Temperature: 'TEMPERATURE' as PhaseControlTypeEnum,
  DeltaTemperature: 'DELTA_TEMP' as PhaseControlTypeEnum,
  Time: 'TIME' as PhaseControlTypeEnum,
  Spillone: 'SPILLONE' as PhaseControlTypeEnum,
  Autoclimate: 'AUTOCLIMATE' as PhaseControlTypeEnum,
  VaporType: 'VAPOR_TYPE' as PhaseControlTypeEnum,
  WavePower: 'WAVE_POWER' as PhaseControlTypeEnum,
  Fan: 'FAN' as PhaseControlTypeEnum
};

export type PhaseCommonSetting = 'PREHEAT' | 'HOLDING' | 'FINISHING' | 'DRYING';
export type PhaseSingleSetting = 'ACTIONS' | 'SOFT_DRY' | 'AIRVENT' | 'MOISTURIZING' | 'AROMA' | 'SMOKE';

export interface PhaseRecipeValue {
  preheat?: PreheatDtoNBK;
  holding?: HoldingDtoNBK;
  drying?: boolean;
  finishing?: RecipeBoDtoNBK.FinishingEnum;
}

export interface ITabImage {
  url: string;
  alt: string;
}

export interface IControlValue {
  minValue: number;
  maxValue: number;
  defaultValue?: number;
};

export interface ISectionList {
  label: PhaseControlTypeEnum;
  /**
   * sometimes a section is disabled 
   * based on the phase position (index) in the phases list
   */
  disabled: boolean | number[];
}

export type CookingTypeSettings = {
  ref: PhaseBoDtoNBK.CookingTypeEnum,
  ui: {
    img: ITabImage;
    label: string;
  };
  phaseSingleSettings: PhaseSingleSetting[];
  patch: (tempUnit: TTemperatureUnit) => {
    phase: { [key: string]: any; };
    recipe: PhaseRecipeValue;
  };
  holding: HoldingConfiguration;
  sectionList: ISectionList[];
  temperature?: IControlValue;
  spillone?: IControlValue;
  time?: IControlValue;
  deltaTemperature?: IControlValue;
};

export interface HoldingConfiguration {
  list: {
    value: number;
    name: string
  }[];
  floor: number;
  ceil: number;
}

export type IDevicePhasesConfig = {
  [key in DeviceModelEnum]: {
    [key in CookingModeEnum]?: {
      phaseCommonSettings: PhaseCommonSetting[];
      preheat?: {
        maxValue: number;
        minValue: number;
        defaultValue: number;
        percentageMinValue: number;
      };
      modes: {
        [key in PhaseBoDtoNBK.CookingTypeEnum]?: CookingTypeSettings;
      };
    } 
  }
};

export const DevicePhasesConfig: IDevicePhasesConfig = {
  ORACLE: {
    COMBI: {
      phaseCommonSettings: ['PREHEAT', 'HOLDING'],
      preheat: {
        maxValue: 300,
        minValue: 36,
        defaultValue: 192,
        percentageMinValue: 0.2
      },
      modes: {
        CONVECTION: {
          ref: "CONVECTION",
          ui: {
            img: {
              url: 'assets/images/btn_forno.png',
              alt: 'CONVECTION'
            },
            label: 'CONVECTION',
          },
          time: {
            minValue: 5,
            maxValue: 86400,
            defaultValue: 5
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 250
          },
          phaseSingleSettings: ['ACTIONS'],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'WAVE_POWER', disabled: true },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          temperature: {
            minValue: 30,
            maxValue: 300,
            defaultValue: 160
          },
          patch: (tempUnit) => {
            return {
              phase: {
                magnetron: 0,
                fanSpeed: 50,
                temperature: getTemperatureValue(160,tempUnit),
                time: 60,
                cookingSettings: undefined,
                fan: undefined,
                airvent: undefined,
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: undefined,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined
              },
            }
          } 
        },
        MICROWAVE: {
          ref: "MICROWAVE",
          ui: {
            img: {
              url: 'assets/images/btn_forno_vapore.png',
              alt: 'MICROWAVE'
            },
            label: 'MICROWAVE'
          },
          time: {
            minValue: 5,
            maxValue: 3600,
            defaultValue: 5
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 250
          },
          phaseSingleSettings: ['ACTIONS'],
          sectionList: [
            { label: 'TEMPERATURE', disabled: true },
            { label: 'WAVE_POWER', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          patch: (unit) => {
            return {
              phase: {
                magnetron: 50,
                fanSpeed: 50,
                temperature: 0,
                time: 10,
                cookingSettings: undefined,
                fan: undefined,
                airvent: undefined,
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: undefined,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined
              },
            }
          } 
        },
        COMBO: {
          ref: "COMBO",
          ui: {
            img: {
              url: 'assets/images/btn_forno_misto.png',
              alt: 'COMBI'
            },
            label: 'COMBI',
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 250
          },
          phaseSingleSettings: ['ACTIONS'],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'WAVE_POWER', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          temperature: {
            minValue: 30,
            maxValue: 300,
            defaultValue: 160
          },
          time: {
            minValue: 5,
            maxValue: 3600,
            defaultValue: 5
          },
          patch: (tempUnit) => {
            return {
              phase: {
                magnetron: 50,
                fanSpeed: 50,
                temperature: getTemperatureValue(160,tempUnit),
                time: 10,
                cookingSettings: undefined,
                fan: undefined,
                airvent: undefined,
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: undefined,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined
              },
            }
          } 
        }
      }
    },
    HSO: {
      phaseCommonSettings: ['PREHEAT'],
      preheat: {
        maxValue: 300,
        minValue: 100,
        defaultValue: 250,
        percentageMinValue: 1
      },
      modes: {
        MICROWAVE: {
          ref: "MICROWAVE",
          ui: {
            img: {
              url: 'assets/images/btn_forno_vapore.png',
              alt: 'MICROWAVE'
            },
            label: 'MICROWAVE',
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 250
          },
          sectionList: [
            { label: 'WAVE_POWER', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          phaseSingleSettings: ['ACTIONS'],
          temperature: {
            minValue: 0,
            maxValue: 0,
            defaultValue: 0
          },
          patch: (tempUnit) => {
            return {
              phase: {
                magnetron: 50,
                fanSpeed: 50,
                temperature: undefined,
                time: 10,
                cookingSettings: undefined,
                fan: undefined,
                airvent: undefined,
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: undefined,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined
              },
            }
          } 
        }
      }
    },
  },
  NABOO: {
    NABOO: {
      phaseCommonSettings: ['PREHEAT', 'HOLDING', 'FINISHING'],
      preheat: {
        maxValue: 320,
        minValue: 30,
        defaultValue: 192,
        percentageMinValue: 0.2
      },
      modes: {
        CONVECTION: {
          ref: "CONVECTION",
          ui: {
            img: {
              url: 'assets/images/btn_forno.png',
              alt: 'CONVECTION'
            },
            label: 'CONVECTION',
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 80
          },
          phaseSingleSettings: ['ACTIONS', 'SOFT_DRY', 'MOISTURIZING', 'AROMA', 'SMOKE', 'AIRVENT'],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'DELTA_TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'SPILLONE', disabled: false },
            { label: 'AUTOCLIMATE', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          temperature: {
            minValue: 30,
            maxValue: 300,
            defaultValue: 160
          },
          spillone: {
            minValue: 1,
            maxValue: 150,
            defaultValue: 80
          },
          deltaTemperature: {
            minValue: 5,
            maxValue: 150,
            defaultValue: 80
          },
          patch(tempUnit) {
            return {
              phase: {
                magnetron: undefined,
                fanSpeed: undefined,
                temperature: undefined,
                time: undefined,
                cookingSettings: {
                  timer: 300,
                  deltaTemperature: getTemperatureValue(160,tempUnit),
                  cameraTemperature: getTemperatureValue(160,tempUnit),
                  spilloneTemperature: getTemperatureValue(1,tempUnit),
                },
                fan: {
                  type: 'NORMAL',
                  speed: 6
                },
                airvent: {
                  type: 'CLOSE',
                  time: 60,
                },
                softDry: false,
                vaporType: undefined,
                moisturizing: 0,
                autoclimate: 100,
                smokegrill:'OFF',
                aroma: 'OFF'
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: 'OFF'
              },
            }
          }
        },
        STEAM: {
          ref: "STEAM",
          ui: {
            img: {
              url: 'assets/images/nabook/btn_forno_vapore.png',
              alt: 'STEAM'
            },
            label: 'STEAM',
          },
          phaseSingleSettings: ['ACTIONS'],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'DELTA_TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'SPILLONE', disabled: false },
            { label: 'VAPOR_TYPE', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          temperature: {
            minValue: 30,
            maxValue: 130,
            defaultValue: 80
          },
          spillone: {
            minValue: 1,
            maxValue: 125,
            defaultValue: 80
          },
          deltaTemperature: {
            minValue: 30,
            maxValue: 130,
            defaultValue: 80
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 80
          },
          patch(tempUnit) {
            return {
              phase: {
                magnetron: undefined,
                fanSpeed: undefined,
                temperature: undefined,
                time: undefined,
                cookingSettings: {
                  timer: 300,
                  deltaTemperature: getTemperatureValue(100,tempUnit),
                  cameraTemperature: getTemperatureValue(100,tempUnit),
                  spilloneTemperature: getTemperatureValue(1,tempUnit),
                },
                fan: {
                  type: 'NORMAL',
                  speed: 6
                },
                airvent: {
                  type: undefined,
                  time: undefined,
                },
                softDry: undefined,
                vaporType: 'TURBO',
                moisturizing: undefined,
                autoclimate: null,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: 'OFF'
              },
            }
          }
        },
        COMBO: {
          ref: "COMBO",
          ui: {
            img: {
              url: 'assets/images/nabook/btn_forno_misto.png',
              alt: 'COMBI'
            },
            label: 'COMBI',
          },
          phaseSingleSettings: ['ACTIONS', 'MOISTURIZING'],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'DELTA_TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'SPILLONE', disabled: false },
            { label: 'AUTOCLIMATE', disabled: false },
            { label: 'FAN', disabled: false }
          ],
          temperature: {
            minValue: 30,
            maxValue: 300,
            defaultValue: 160
          },
          spillone: {
            minValue: 1,
            maxValue: 150,
            defaultValue: 80
          },
          deltaTemperature: {
            minValue: 5,
            maxValue: 150,
            defaultValue: 80
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'Soft'
              },
              {
                value: 2,
                name: 'Medium'
              },
              {
                value: 3,
                name: 'Hard'
              }
            ],
            floor: 30,
            ceil: 80
          },
          patch(tempUnit) {
            return {
              phase: {
                magnetron: undefined,
                fanSpeed: undefined,
                temperature: undefined,
                time: undefined,
                cookingSettings: {
                  timer: 300,
                  deltaTemperature: 
                    getTemperatureValue(DevicePhasesConfig.NABOO.NABOO?.modes.COMBO?.deltaTemperature?.defaultValue!,tempUnit),
                  cameraTemperature: 
                    getTemperatureValue(DevicePhasesConfig.NABOO.NABOO?.modes.COMBO?.temperature?.defaultValue!,tempUnit),
                  spilloneTemperature: 
                    getTemperatureValue(DevicePhasesConfig.NABOO.NABOO?.modes.COMBO?.spillone?.defaultValue!,tempUnit),
                },
                fan: {
                  type: 'NORMAL',
                  speed: 6
                },
                airvent: {
                  type: undefined,
                  time: undefined,
                }, 
                softDry: undefined,
                vaporType: undefined,
                moisturizing: 0,
                autoclimate: 100,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: {
                  type: 'CONVECTION',
                  temperature: 0
                },
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: 'OFF'
              },
            }
          }
        }
      }
    }
  }, 
  NEO: {
    NEO: {
      phaseCommonSettings: ['HOLDING', 'DRYING'],
      modes: {
        LEAVENING: {
          ref: "LEAVENING",
          ui: {
            img: {
              url: 'assets/images/nabook/leavening.png',
              alt: 'Leavening'
            },
            label: 'LEAVENING',
          },
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: [1, 4] },
            { label: 'AUTOCLIMATE', disabled: [0, 1] },
            { label: 'FAN', disabled: false }
          ],
          phaseSingleSettings: [],
          temperature: {
            minValue: -40,
            maxValue: 25,
            defaultValue: 20
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'On'
              },
            ],
            floor: 0,
            ceil: 85
          },
          patch(tempUnit) {
            return {
              phase: {},
              recipe: {
                preheat: undefined,
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined,
              },
            }
          }
        },
        SHOCK_FREEZING: {
          ref: "SHOCK_FREEZING",
          ui: {
            img: {
              url: 'assets/images/nabook/shock_freezing.png',
              alt: 'Shock freezing'
            },
            label: 'Shock freezing',
          },
          phaseSingleSettings: [],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'SPILLONE', disabled: false },
            { label: 'FAN', disabled: true }
          ],
          temperature: {
            minValue: -40,
            maxValue: 45,
            defaultValue: 20
          },
          spillone: {
            minValue: -38,
            maxValue: 85,
            defaultValue: 10
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'On'
              },
            ],
            floor: -40,
            ceil: 0
          },
          patch(tempUnit) {
            return {
              phase: {
                magnetron: undefined,
                fanSpeed: undefined,
                temperature: undefined,
                time: undefined,
                cookingSettings: {
                  timer: 14400,
                  deltaTemperature: undefined,
                  cameraTemperature: getTemperatureValue(-20,tempUnit),
                  spilloneTemperature: getTemperatureValue(-18,tempUnit),
                },
                fan: {
                  type: 'NORMAL',
                  speed: 5
                },
                airvent: {
                  type: undefined,
                  time: undefined,
                },
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: undefined,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: undefined,
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined
              },
            }
          }
        },
        BLAST_CHILLING: {
          ref: "BLAST_CHILLING",
          ui: {
            img: {
              url: 'assets/images/nabook/blast_chilling.png',
              alt: 'Blast chilling'
            },
            label: 'Blast chilling',
          },
          phaseSingleSettings: [],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'SPILLONE', disabled: false },
            { label: 'FAN', disabled: true }
          ],
          temperature: {
            minValue: -20,
            maxValue: 45,
            defaultValue: 20
          },
          spillone: {
            minValue: -18,
            maxValue: 85,
            defaultValue: 10
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'On'
              },
            ],
            floor: -20,
            ceil: 20
          },
          patch(tempUnit) {
            return {
              phase: {
                magnetron: undefined,
                fanSpeed: undefined,
                temperature: undefined,
                time: undefined,
                cookingSettings: {
                  timer: 5400,
                  deltaTemperature: undefined,
                  cameraTemperature: getTemperatureValue(0,tempUnit),
                  spilloneTemperature: getTemperatureValue(3,tempUnit),
                },
                fan: {
                  type: 'NORMAL',
                  speed: 5
                },
                airvent: {
                  type: undefined,
                  time: undefined,
                },
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: undefined,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: undefined,
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: undefined,
                finishing: undefined
              },
            }
          }
        },
        SLOW_COOKING: {
          ref: "SLOW_COOKING",
          ui: {
            img: {
              url: 'assets/images/nabook/slow_cooking.png',
              alt: 'Slow coocking'
            },
            label: 'Slow coocking',
          },
          phaseSingleSettings: [],
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'SPILLONE', disabled: false },
            { label: 'AUTOCLIMATE', disabled: false },
            { label: 'FAN', disabled: true }
          ],
          temperature: {
            minValue: 22,
            maxValue: 85,
            defaultValue: 20
          },
          spillone: {
            minValue: -20,
            maxValue: 78,
            defaultValue: 10
          },
          holding: {
            list: [
              {
                value: 0,
                name: 'Off'
              },
              {
                value: 1,
                name: 'On'
              },
            ],
            floor: 0,
            ceil: 85
          },
          patch(tempUnit) {
            return {
              phase: {
                magnetron: undefined,
                fanSpeed: undefined,
                temperature: undefined,
                time: undefined,
                cookingSettings: {
                  timer: 3600,
                  deltaTemperature: undefined,
                  cameraTemperature: getTemperatureValue(65,tempUnit),
                  spilloneTemperature: getTemperatureValue(45,tempUnit),
                },
                fan: {
                  type: 'NORMAL',
                  speed: 5
                },
                airvent: {
                  type: undefined,
                  time: undefined,
                },
                softDry: undefined,
                vaporType: undefined,
                moisturizing: undefined,
                autoclimate: 20,
                smokegrill: undefined,
                aroma: undefined
              },
              recipe: {
                preheat: undefined,
                holding: {
                  type: 'Off',
                  temperature: 0
                },
                drying: false,
                finishing: undefined
              },
            }
          }
        }
      }
    }
  }
};

export const TEMP_TRESHOLD_NABOO = 260;
export const NABOO_BOOSTED_TRESHOLD = 300;
export const MOISTURIZING_FLOOR = 100;
export const TURBO_VAPOR_TYPE_TRESHOLD = 100;
export const EC0_VAPOR_TYPE_TRESHOLD = 90;

export type TTemperatureUnit = 'C' | 'F';

export interface IConfigSliderCookingStep {
  ceil?: number;
  floor?: number;
  step?: number;
  time?: boolean;
  firstStep?: number;
  minLimit?: number;
  maxLimit?: number;
}

export const convertToFahrenheit = (celsius: number): number => {
  return parseInt(((celsius * 9) / 5 + 32).toFixed(2));
};

export const convertToCelsius = (farhenheit: number): number => {
  return parseInt((((farhenheit - 32) * 5) / 9).toFixed(2));
};

export const convertRecipeToFahrenheit = (recipe: RecipeBoDtoNBK) => {
  recipe.preheat && recipe.preheat.temperature
    ? (recipe.preheat.temperature = convertToFahrenheit(
        recipe.preheat.temperature
      ))
    : null;

  recipe.holding && recipe.holding.temperature
    ? (recipe.holding.temperature = convertToFahrenheit(
        recipe.holding.temperature
      ))
    : null;

  recipe.phases?.forEach((phase) => {
    phase && phase.temperature
      ? (phase.temperature = convertToFahrenheit(phase.temperature))
      : null;
  });
};

export const convertRecipeToCelsius = (recipe: RecipeBoDtoNBK) => {
  recipe.preheat && recipe.preheat.temperature
    ? (recipe.preheat.temperature = convertToCelsius(
        recipe.preheat.temperature
      ))
    : null;

  recipe.holding && recipe.holding.temperature
    ? (recipe.holding.temperature = convertToCelsius(
        recipe.holding.temperature
      ))
    : null;

  recipe.phases?.forEach((phase) => {
    phase && phase.temperature
      ? (phase.temperature = convertToCelsius(phase.temperature))
      : null;
  });
};

export const preheatConfiguration = (
  deviceModel: DeviceModelEnum,
  cookingMode: CookingModeEnum,
  tempUnit: TTemperatureUnit
) => {
  const preheat = DevicePhasesConfig[deviceModel][cookingMode]?.preheat;
  return preheat ? {
    ceil: getTemperatureValue(preheat.maxValue, tempUnit),
    floor: getTemperatureValue(preheat.minValue, tempUnit),
    defaultValue: getTemperatureValue(preheat.defaultValue, tempUnit),
    percentageMinValue: preheat.percentageMinValue
  } : undefined;

};

export const timeConfiguration = (
  deviceModel: DeviceModelEnum,
  cookingMode: CookingModeEnum, 
  cookingType: PhaseBoDtoNBK.CookingTypeEnum ) => {
    const time = DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]?.time;
    return time ? {
      ceil: time.maxValue,
      floor: time.minValue,
      minLimit: time.minValue,
      default: time.defaultValue!
    } : undefined;
};

export const temperatureConfiguration = (
  deviceModel: DeviceModelEnum,
  cookingMode: CookingModeEnum, 
  cookingType: PhaseBoDtoNBK.CookingTypeEnum,
  tempUnit: TTemperatureUnit ) => {
    const temperature = DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]?.temperature;
    return temperature ? {
      ceil: getTemperatureValue(temperature.maxValue, tempUnit),
      floor: getTemperatureValue(temperature.minValue, tempUnit),
      minLimit: getTemperatureValue(temperature.minValue, tempUnit),
      default: getTemperatureValue(temperature.defaultValue!, tempUnit)
    } : undefined;
};

export const spilloneConfiguration = (
  deviceModel: DeviceModelEnum,
  cookingMode: CookingModeEnum, 
  cookingType: PhaseBoDtoNBK.CookingTypeEnum,
  tempUnit: TTemperatureUnit
) => {
  const spillone = DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]?.spillone;
  return spillone ? {
    ceil: getTemperatureValue(spillone.maxValue, tempUnit),
    floor: getTemperatureValue(spillone.minValue, tempUnit),
    minLimit: getTemperatureValue(spillone.minValue, tempUnit),
  } : undefined;
};

export const deltaTempConfiguration = (
  deviceModel: DeviceModelEnum,
  cookingMode: CookingModeEnum, 
  cookingType: PhaseBoDtoNBK.CookingTypeEnum,
  tempUnit: TTemperatureUnit
) => {
  const deltaTemperature = DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]?.deltaTemperature;
  return deltaTemperature ? {
    ceil: getTemperatureValue(deltaTemperature.maxValue, tempUnit),
    floor: getTemperatureValue(deltaTemperature.minValue, tempUnit),
    minLimit: getTemperatureValue(deltaTemperature.minValue, tempUnit),
  } : undefined;
};

export const phaseCommonSettings = (
  deviceModel: DeviceModelEnum,
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
) => {
  return DevicePhasesConfig[deviceModel][cookingMode]?.phaseCommonSettings;
};

export const phaseSingleSettings = (
  deviceModel: DeviceModelEnum,
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
  cookingType: PhaseBoDtoNBK.CookingTypeEnum
) => {
  return DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]?.phaseSingleSettings;
};

export const holdingConfiguration = (
  deviceModel: DeviceModelEnum,
  cookingMode: RecipeBoDtoNBK.CookingModeEnum, 
  cookingType: PhaseBoDtoNBK.CookingTypeEnum,
  tempUnit: TTemperatureUnit
) => {
  const holding = DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]!.holding!;
  return {
    list: holding.list,
    ceil: getTemperatureValue(holding.ceil, tempUnit),
    floor: getTemperatureValue(holding.floor, tempUnit)
  };
};

export const patchValue = (
  deviceModel: DeviceModelEnum,
  cookingMode: RecipeBoDtoNBK.CookingModeEnum, 
  cookingType: PhaseBoDtoNBK.CookingTypeEnum,
  tempUnit: TTemperatureUnit
) => {
  return DevicePhasesConfig[deviceModel][cookingMode]?.modes[cookingType]?.patch(tempUnit);
};

export const getTemperatureValue = (value: number, tempUnit: TTemperatureUnit) => {
  return tempUnit === 'C'
      ? value
      : convertToFahrenheit(value);
}
